import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import Helmet from 'react-helmet'

import PostAuthorAndDate from 'components/PostAuthorAndDate'

import { useNewsPosts } from 'hooks'

import './NewsFeed.scss'

type Props = {
  webview?: boolean
}

export default function NewsFeed({ webview }: Props) {
  const posts = useNewsPosts()

  return (
    <>
      <Helmet>
        <title>Jevitty News</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div
        className={classNames('row row-cols-1', {
          'row-cols-md-2 row-cols-lg-3 pt-3': !webview,
        })}
      >
        {posts
          ? posts.map(({ slug, title, image, description, date, author }) => (
              <div className="col" key={slug}>
                <article
                  className={
                    webview ? 'news-feed-item--webview' : 'news-feed-item'
                  }
                >
                  <Link
                    className="news-feed-item__link"
                    to={`${webview ? '/webview' : ''}/news/${slug}/`}
                  >
                    <div className="news-feed-item__image-container">
                      <img
                        className="news-feed-item__image"
                        src={image}
                        alt="Post image"
                      />
                    </div>
                    <h2 className="news-feed-item__title">{title}</h2>
                  </Link>

                  {(author || date) && (
                    <PostAuthorAndDate
                      className="news-feed-item__meta"
                      {...{ author, date }}
                    />
                  )}

                  {description && (
                    <div
                      className="news-feed-item__description"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    ></div>
                  )}

                  <Link
                    className={classNames(
                      'news-feed-item__read-more btn btn-secondary',
                      { 'btn-lg': webview },
                    )}
                    to={`${webview ? '/webview' : ''}/news/${slug}/`}
                  >
                    Read more
                  </Link>
                </article>
              </div>
            ))
          : 'No posts yet'}
      </div>
    </>
  )
}
