import React from 'react'

import Layout from 'components/Layout'
import NewsFeed from 'components/NewsFeed'

export default function NewsPage() {
  return (
    <Layout className="news-page" webview>
      <NewsFeed webview />
    </Layout>
  )
}
