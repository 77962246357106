import React, { ReactElement } from 'react'
import classNames from 'classnames'

import './PostAuthorAndDate.scss'

type MetaProps = {
  className?: string
  author?: string
  date?: string
}

export default function PostAuthorAndDate({
  className,
  author,
  date,
}: MetaProps): ReactElement {
  return (
    <p className={classNames('news-post__meta', className)}>
      {author && (
        <>
          <span className="news-post__meta__author">By {author}</span>
          {' • '}
        </>
      )}
      {date && <span className="news-post__meta__date">{date}</span>}
    </p>
  )
}
